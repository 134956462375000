<template>
    <div>
        <Modal footer-hide :mask="true" :mask-closable="false" :scrollable="true" class-name="vertical-center-modal" title="详情" v-model="dialogShow" :width="faultModalWidth">
            <div style="background:#fff">
                <Row :gutter="10">
                    <Col :lg="videoWidthRatio" :md="videoWidthRatio" :sm="videoWidthRatio" :xs="videoWidthRatio" class="flexVideo">
                    <Tabs size="small">
                        <TabPane label="视频" class="TimeDiv">
                            <video :src="videoUrl" controls ref="video" v-if="videoUrl != '' && videoUrl != null">
                                您的浏览器不支持video元素。
                            </video>
                            <!-- <svg-icon :icon-class="'notViodeo'" class="svg-icon_not_video" v-if="!videoUrl" /> -->
                            <br />
                            <font style="margin-left:205px" v-if="!videoUrl">暂无视频</font>
                        </TabPane>
                        <TabPane label="图片">
                            <div class="fault-imgs-box" v-if="faultImageList.length > 0">
                                <div class="fault-img" :key="index" dis-hover v-for="(item, index) in faultImageList">
                                    <img :src="item" style="max-width:100%" />
                                </div>
                            </div>
                            <div v-else style="height:100%; display: flex; justify-content:center;align-items: center;">
                                <font>暂无图片</font>
                            </div>
                        </TabPane>
                    </Tabs>
                    </Col>
                    <Col :lg="rescueWidthRatio" :md="rescueWidthRatio" :sm="rescueWidthRatio" :xs="rescueWidthRatio" v-if="realDataShow">
                    <!-- <div>123</div> -->
                    <div class="live-progress mt16" style="position: relative">
                        <h2>困人救援</h2>
                        <div class="item" v-for="(item, index) in realData" :key="index">
                            <div class="box">
                                <h5 class="title" style="
                       overflow: hidden;
                       white-space: nowrap;
                       text-overflow: ellipsis;
                  ">
                                    {{ item.comment }}
                                </h5>
                                <div class="text">
                                    <span>{{ item.status_time }}</span>
                                    <span style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      max-width: 55px;
                    ">{{ item.handler }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col :lg="faultInfoWidthRatio" :md="faultInfoWidthRatio" :sm="faultInfoWidthRatio" :xs="faultInfoWidthRatio">
                    <CellGroup>
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.v_fault_id + '' : '--'
                " title="故障ID" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.v_elevator_name + '' : '--'
                " title="电梯编号">
                            <div :title="faultInfoDetail.v_elevator_name"  class="cell-extra-value" slot="extra">{{faultInfoDetail.v_elevator_name
                                ? faultInfoDetail.v_elevator_name + ''
                                : '--'}}</div>
                        </Cell>
                        <Cell :extra="
                  faultInfoDetail.v_address
                    ? faultInfoDetail.v_address + ''
                    : '--'
                " title="电梯地址">
                            <div :title="faultInfoDetail.v_address" class="cell-extra-value" slot="extra">{{faultInfoDetail.v_address
                                ? faultInfoDetail.v_address + ''
                                : '--'}}</div>
                        </Cell>
                        <Cell :extra="
                  faultInfoDetail.i_elevator_type == 1
                    ? '直梯'+ ''
                    : faultInfoDetail.i_fault_type == 2?'扶梯':'--'
                " title="电梯类型" />
                        <Cell :extra="
                  faultInfoDetail.dt_report_time
                    ? faultInfoDetail.dt_report_time + ''
                    : '--'
                " title="上报时间" />
                        <Cell :extra="
                  faultInfoDetail.dt_end_time
                    ? faultInfoDetail.dt_end_time + ''
                    : '--'
                " title="结束时间" />
                        <Cell :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_fault_type +
                      '-' +
                      faultInfoDetail.v_fault_name +
                      ''
                    : '--'
                " title="故障类型" />
                        <Cell :extra="
                  faultInfoDetail.v_fault_second_type
                    ? faultInfoDetail.v_fault_second_type +
                      '-' +
                      faultInfoDetail.v_fault_second_name +
                      ''
                    : '--'
                " title="故障子类型" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.i_fault_num + '' : '--'
                " title="上报次数" />
                        <Cell :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_mode_status_name + ''
                    : '--'
                " title="所处服务模式" />
                        <Cell :extra="
                  faultInfoDetail.i_floor ? faultInfoDetail.i_floor + '' : '--'
                " title="故障时梯楼层" />
                        <Cell :extra="
                  faultInfoDetail ? faultInfoDetail.i_status_name + '' : '--'
                " selected title="当前故障状态" />
                    </CellGroup>
                    </Col>
                </Row>
            </div>
        </Modal>
    </div>
</template>
<script>
import {
    getFaultDetail,
    getElevatorFaultRealTimeByEvent
} from '@/api/repair/index.js'
export default {
    name: 'MXFaultModal',
    components: {},
    props: {

    },
    data() {
        return {
            faultModalWidth: 1200,
            dialogShow: false,
            videoWidthRatio: 12,
            rescueWidthRatio: 6,
            faultInfoWidthRatio: 6,
            faultModalWidth: 1200,

            realDataShow: false,
            realData: [],

            videoUrl: null,
            faultImageList: [],

            faultInfoDetail: {},

        }
    },
    mounted() {},
    methods: {
        show(faultId, eventId) {
            getFaultDetail(faultId).then(res => {
                if (res.code === '0000') {
                    this.faultInfoDetail = res.info.detail
                    this.videoUrl = res.info.videoUrl
                    this.faultImageList = res.info.imageList
                }
            })
            if (eventId) {
                getElevatorFaultRealTimeByEvent(eventId).then(res => {
                    this.realData = res.info
                })
            }

            this.realDataShow = eventId ? true : false
            this.updateFaultModal(this.realDataShow)

            this.dialogShow = true
        },

        updateFaultModal(realDataShow) {
            if (realDataShow) {
                this.faultModalWidth = 1200
                this.videoWidthRatio = 12
                this.faultInfoWidthRatio = 6
                this.rescueWidthRatio = 6
            } else {
                this.faultModalWidth = 1000
                this.videoWidthRatio = 16
                this.faultInfoWidthRatio = 8
            }
        },
    },
    watch: {

    }

}
</script>
<style lang="scss" scoped>
video {
    width: 100%;
    max-height: 500px;
}

.fault-img {
    margin-bottom: 20px;
}

.fault-img:last-of-type {
    margin-bottom: 0px;
}

.fault-imgs-box {
    max-height: 500px;
    overflow-y: auto;
}

.cell-extra-value {
    line-height: normal;
    white-space: normal;
    text-align: right;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>